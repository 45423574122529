:root {
  --primary-color: #2c8526;
}

.custom-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.errorText {
  color: red;
  position: absolute;
  bottom: -32px;
  left: 10px;
}

.custom-input {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 1.5px solid #949494;
  background: #fcfcfc;
  color: #949494;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.178px;
  flex: 1 0 0;
  padding: 11px;
}

.edit-icon {
  cursor: pointer;
  width: 42px;
  height: 42px;
  padding: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: var(--primary-color);
}
