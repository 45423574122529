.otpForm {
  margin: 28px auto;
  display: flex;
  justify-content: center;
  width: 576px;
  max-width: 576px;
  gap: 20px;
}

.otpInput {
  width: 28px;
  height: 42px;
  border-radius: 8px;
  border: 1.5px solid #949494;
  background: #fcfcfc;
  color: #949494;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.178px;
  text-align: center;
}

.resendCodeText {
  color: #757575;
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.6px;
}

.resendText {
  color: #2c8526;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 1.2px;
  margin-left: 7px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border: none;
  box-shadow: none;
  margin-top: 20px;
  margin-bottom: 28px;
}

.backButton {
  border-radius: 4px;
  border: 1px solid #2c8526;
  background: #fcfcfc;
  color: #353535;
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.6px;
  padding: 8px 0;
  width: 140px;
}

.verifyButton {
  border-radius: 4px;
  background: #2c8526;
  color: #fcfcfc;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.6px;
  padding: 8px 0;
  width: 140px;
}
