.appCardContainer {
  border-radius: 8px;
  border: 1px solid #949494;
  background: #fff;
  box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.13);
  flex-basis: 30%;
  flex-grow: 1;
  gap: 12px;
}

.appHeaderText {
  color: #363636;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

.appSubHeaderText {
  text-align: center;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 12px;
  max-height: 24px;
  min-height: 24px;
  letter-spacing: 0.6px;
  max-width: 84%;
}

.appButton {
  display: none;
  text-align:"center";
  width: 64px;
  height: 26px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #2C8526;
}

.appButton span{
  color:#FFFFFF;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.6px;
}

.boxes:hover{
  .appButton{
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
  }
}
