.forgetPasswordContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgotPasswordForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 104px;
}

.forgotPasswordFormGroup {
  display: flex;
  margin: auto;
  min-width: 576px;
  padding: 12px 16px;
  justify-content: center;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border: none;
  box-shadow: none;
  margin-top: 20px;
  margin-bottom: 28px;
}

.backButton {
  border-radius: 4px;
  border: 1px solid #2c8526;
  background: #fcfcfc;
  color: #353535;
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.6px;
  padding: 8px 0;
  width: 140px;
}

.resetButton {
  border-radius: 4px;
  background: #2c8526;
  color: #fcfcfc;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.6px;
  padding: 8px 0;
  width: 140px;
}
