.formHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.formHeaderText {
  color: #363636;
  font-size: 24px;
  font-weight: 400;
  line-height: 100%;
  font-family: Roboto;
}

.formSubHeaderText {
  color: #757575;
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.6px;
  font-family: Roboto;
}
