.successTitle {
  color: #fcfcfc;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 20px */
}
.sucessSubtitle {
  margin-top: 4px;
  color: #fcfcfc;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
  letter-spacing: 0.178px;
}

.crossButton {
  position: relative;
  top: -24px;
}
