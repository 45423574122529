.Toastify__toast-container {
  padding: 0% !important;
  border-radius: 18px 0px 0px 18px !important;
}

.Toastify__toast-body {
  padding: 0% !important;
  border-radius: 18px 0px 0px 18px !important;
}

.Toastify__toast-theme--light {
  padding: 0% !important;
  border-radius: 18px 0px 0px 18px !important;
}

.Toastify__toast-container--top-right {
  right: 0 !important;
}
