.signUpForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 166px;
}

.signUpFormGroup {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin: auto;
  margin-bottom: 4px;
  min-width: 576px;
  padding: 12px 16px;
}

.signUpButton {
  width: 358px;
  max-width: 358px;
  background: #2c8526;
  color: #fcfcfc;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  padding: 8px;
  border-radius: 4px;
  box-shadow: none;
  border: none;
}
