.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 104px;
}

.loginFormGroup {
  display: flex;
  margin: auto;
  min-width: 576px;
  padding: 12px 16px;
  justify-content: center;
}

.loginButton {
  width: 358px;
  max-width: 358px;
  background: #2c8526;
  color: #fcfcfc;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  padding: 8px;
  border-radius: 4px;
  box-shadow: none;
  border: none;
}

.forgetPasswordText {
  color: #2c8526;
  font-size: 12px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 1.2px;
  margin-top: 16px;
  cursor: pointer;
}
