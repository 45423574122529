.updateProfileFormGroup {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
}

.updateProfileForm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.updateProfileBackButton {
  min-width: 48%;
  border-radius: 4px;
  border: 1px solid #2c8526;
  background: #fcfcfc;
  color: #353535;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.6px;
  padding: 8px 0;
  width: 140px;
}

.updateProfileButton {
  min-width: 48%;
  border-radius: 4px;
  background: #2c8526;
  color: #fcfcfc;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.6px;
  padding: 8px 0;
  width: 140px;
}
