.formContainer {
  width: fit-content;
  border-radius: 8px;
  border: 1px solid #949494;
  background: #fff;
  box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.13);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 48px;
}
