.footerTitleText {
  color: #757575;
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.6px;
}

.footerLink {
  color: #2c8526;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 1.2px;
  margin-top: 4px;
  cursor: pointer;
}
